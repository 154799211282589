<template>
  <div class="land-trust">
    <div class="quick-banner">
      <p>农机服务11</p>
      <img src="../../assets/imgs/banner2.png" alt="" />
    </div>
    <div class="land-main">
      <ul class="detail">
        <li>名称：{{ info.busName }}</li>
        <li>地址：{{ info.busAddress }}</li>
        <li>联系电话：{{ info.phone }}</li>
        <li>经营范围：</li>
        <li>{{ info.busRange }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      info: ""
    };
  },
  mounted() {
    // this.getData();
    this.info = this.$route.params.item;
    console.log( this.info );
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.land-trust {
  background: #fff;
  li {
    list-style: none;
  }
}
.quick-banner {
  position: relative;
  height: 180px;
  overflow: hidden;

  p {
    position: absolute;
    top: 40px;
    left: 20%;
    font-size: 32px;
    color: #fff;
  }
}

.land-main {
  width: 1472px;
  margin: 0 auto;
  text-align: left;

  .detail {
    li {
      padding: 20px 0;
    }
  }


  .new-list {
    margin-top: 20px;
    .news-tab {
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      position: relative;

      .input-with-select {
        position: absolute;
        right: 0;
        top: -10px;
        width: auto;
      }
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
    }

    .new-des {
      ul,
      li {
        padding: 0;
        margin: 0;
      }

      ul {
        padding-top: 20px;
      }
      ul li {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px dashed #dcdcdc;

        .img {
          img {
            width: 120px;
            height: 100px;
          }
        }

        .des {
          padding-left: 20px;
          text-align: left;

          h4 {
            font-size: 24px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .news-des {
    margin-bottom: 20px;
  }

  .map-area {
    margin: 20px 0;
    height: 500px;
  }
}
</style>

<style lang="less">
.land-trust .el-table th {
  background: #0668b3;
  color: #fff;
}
</style>
